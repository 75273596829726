import { useState, useRef } from "react";
import "./registration.css";
import emailjs from "@emailjs/browser";
import { db } from "../../Firebase";
import { doc, Firestore, setDoc } from "firebase/firestore";
import { useGetWaitlistResults } from "../../hooks/useGetWaitlistResults";

const D1SundayLeagueWaitlistRegistration = () => {
  const form = useRef();
  const [waitlist, setWaitlist] = useGetWaitlistResults("d1-sunday-league");
  // setDoc(doc(db, "Waitlist", "test"), { test: "test" });
  //NOTE: When using EmailJS, combine all player information
  //into one big string so it can be sent correctly
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_8f2pqna",
        "template_4w5sspf",
        form.current,
        "t-FZ46K78lk_GTwLu"
      )
      .then(
        (result) => {
          //TODO: double check what form values have the name
          let data = {
            "First Name": form.current[2].value,
            "Last Name": form.current[3].value,
            "Team Name": form.current[4].value,
            "Captain Phone": form.current[5].value,
            "Captain Email": form.current[6].value,
            Division: "d1-sunday-league",
          };
          setDoc(
            doc(
              db,
              "Waitlist",
              `${form.current[0].value} ${form.current[1].value}`
            ),
            data
          );

          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="page registration">
      <h2>D1 Sunday League Waitlist Registration</h2>
      <form ref={form} onSubmit={handleSubmit}>
        <input hidden name="division" value="Division 1" />
        <input hidden name="otherRegistration" value="Waitlist" />
        <ul className="form-style-1">
          <li className="form-element">
            <label htmlFor="firstName">First Name</label>
            <input id="firstName" name="firstName" type="text" required></input>
          </li>
          <li className="form-element">
            <label htmlFor="lastName">Last Name</label>
            <input id="lastName" name="lastName" type="text" required></input>
          </li>
          <li className="form-element">
            <label htmlFor="teamName">Team Name</label>
            <input id="teamName" name="teamName" type="text" required></input>
          </li>
          <li className="form-element">
            <label htmlFor="captainPhone">Captain Phone Number</label>
            <input
              id="captainPhone"
              name="captainPhone"
              type="text"
              required
            ></input>
          </li>
          <li className="form-element">
            <label htmlFor="captainEmail">Captain Email</label>
            <input
              id="captainEmail"
              name="captainEmail"
              type="text"
              required
            ></input>
          </li>
          <li>
            <input type="submit" value="Submit" />
          </li>
        </ul>
      </form>
      {/* <br></br>
      <h2>Waitlist</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Team</th>
          </tr>
        </thead>
        <tbody>
        {waitlist && waitlist.length > 0 && waitlist.map((item) => (
            <tr key={item.id}>
              <td>{item.value['First Name']} {item.value['Last Name']}</td>
              <td>{item.value['Team Name']}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
};

export default D1SundayLeagueWaitlistRegistration;
