import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MenuToggle } from "./menuToggle";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {logout, createAccount, loginEmailPassword, db} from '../../Firebase';
import "./navLinks.css";

const auth = getAuth();


const NavLinksContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const LinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
  background-color: #ffffff;
  width: 100%;
  flex-direction: column;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 1;
`;

const LinkItem = styled.li`
  width: 100%;
  padding: 0 1.1em;
  color: #222;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  margin-bottom: 10px;
`;

const Marginer = styled.div`
  height: 2em;
`;

export function MobileNavLinks(props) {
  const [isOpen, setOpen] = useState(false);
  const [showLeagues, setShowLeagues] = useState(false);
  let user = localStorage.getItem('User')

  function toggleLeagues() {
    setShowLeagues(!showLeagues)
  }

  function onClick() {
    setOpen(!isOpen);
  }
  
  return (
    <div className="navlinks mobile navlinks--container">
      <MenuToggle isOpen={isOpen} toggle={onClick} />
      {/* {isOpen && ( */}
      <ul className={`navlinks--links-wrapper ${isOpen ? "open" : "closed"}`}>
          
        <li className="navlinks--link-item">
          <a href="/registration">Registration</a>
        </li>
        <li className="navlinks--link-item">
          <a href="/statistics">Stats</a>
        </li>
        <li className="navlinks--link-item">
          <a href="https://themecca.pixieset.com/themeccapro-am/">Gallery</a>
        </li>
        {/*<li className="navlinks--link-item">
          <a href="/contact">Contact Us</a>
        </li> */}
        {/* <li className="navlinks--link-item">
          <a href="/exhibitions">Sessions</a>
        </li> */}
        {/* <li className="navlinks--link-item">
          <a href="/leagues">Leagues</a>
        </li>
        {/* {showLeagues &&
        <>
        <li className="navlinks--link-item">
          <a href="/d1-sunday-league">D1 Sunday League</a>
        </li>
        <li className="navlinks--link-item">
          <a href="/d2-sunday-league">D2 Sunday League</a>
        </li>
        </>
          } */}
        {/* <li className="navlinks--link-item">
          <a href="/openruns">Open Runs</a>
        </li>
        <li className="navlinks--link-item">
          <a href="/tournaments">Tournaments</a>
        </li> */}
        {/* <li className="navlinks--link-item">
          <a href="/memberships">Memberships</a>
        </li> 
        {user && <>
          <li className="navlinks--link-item">
          <a href="/account">Account</a>
        </li> */}
        {/* {user && <> 
        <li className="navlinks--link-item">
          <a onClick={() => logout()}>Logout</a>
        </li>
        </>} */}
        {/*
        {!user && <>
          <li className="navlinks--link-item">
            <a href="/signup">Sign Up</a>
          </li>
          <li className="navlinks--link-item">
            <a href="/signin">Sign In</a>
          </li>
        </>} */}
        <Marginer />
      </ul>
      {/* )} */}
    </div>
  );
}
