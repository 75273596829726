import React from 'react';

const BigInfoPanel = (data) => {
    return (
<div className="league-info--box format">
    <h2 className="league-info--title">{data.header}</h2>
    <div className="league-info--content">
        {data.children}
    </div>
</div>
    )
}

export default BigInfoPanel;