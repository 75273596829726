import React, {useState} from "react";
import Slider from "./Slider";
import "./Home.css";
import ResultsViewByWeek from "../Results/ResultsViewByWeek";
import Standings from "../Standings/Standings";
import { weeksBetweenDates } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetGamesByWeek } from "../../hooks/useGetGamesByWeek";
import { useGetGameResultsByWeek } from "../../hooks/useGetGameResultsByWeek";
import { useGetTeams } from "../../hooks/useGetTeams";
import EventBoxMinimal from "../Results/EventBoxMinimal";
import{
faArrowLeft,
faArrowRight,
faCaretLeft,
faCaretRight
} from "@fortawesome/free-solid-svg-icons";

export default () => {
  let thisWeek = weeksBetweenDates(new Date(2024, 1, 24), new Date(), 0);
  if(thisWeek > 11){
    thisWeek = 11
  }
  const [currentWeek, setCurrentWeek] = useState(thisWeek);
  const [currentLeague, setCurrentLeague] = useState('562')
  let [games] = useGetGamesByWeek(currentWeek.toString());
  //console.log('_log home games: ', games)
  let [gameResults] = useGetGameResultsByWeek(currentWeek.toString());
  console.log('_log home game results: ', gameResults)
  const [documents] = useGetTeams('d1-sunday-league');
  console.log('_log home teams: ', documents)
  let teams = documents.sort((a, b) => b.value['Wins'] - a.value['Wins'])
  var video = document.getElementById('video')
  if(video){
    video.muted = true
    video.loop = true
    video.playsInline = true
    video.play()
  }
  function changeWeek(increment) {
    if (increment === "prev" && currentWeek > 1) {
      let week = 1;
      if (currentWeek != 1) {
        week = currentWeek - 1;
      }
      setCurrentWeek(week);
    }
    //make this go up to number of weeks in db?
    if (increment === "next" && currentWeek < 11) {
      let week = currentWeek + 1;
      setCurrentWeek(week);
    }
  }
  function changeLeague(){
    currentLeague === '562' ? setCurrentLeague('JUICE COUNTY') : setCurrentLeague('562')
  }

  return (
    <div className="home">
      <div className="page">
        <div className="slider-container-container">
          {/* <Slider></Slider> */}
          <div>
          {/* <a href="https://www.youtube.com/embed/g7SdhtxuJYA">WATCH FULL VIDEO</a> */}
          </div>
          
          {/* <iframe src="https://www.youtube.com/embed/g7SdhtxuJYA?autoplay=1&mute=1&loop=1&controls=0&rel=0&showinfo=0&playlist=g7SdhtxuJYA" frameBorder="0" allow="autoplay"></iframe> */}
          <div className="video video--cover">
            <video id="video" width="100%" height="auto" poster="../../images/The Mecca_d00a_00a-01.svg" autoPlay loop muted playsInline preload="none" controls="false" data-setup='{"example_option":true}'>
              <source src="https://themeccaproam.s3.amazonaws.com/Homepagevideo.mp4" type="video/mp4"/>
              Your browser does not support the video tag!
            </video>
            {/* <div className="video--button">
              <a href="https://www.youtube.com/watch?v=uzIf-QmzfLw" target="_blank" className="button button--medium">Watch Full Video</a>
            </div> */}
          </div>
        </div>
        <h2>GAME SCHEDULE</h2>
        <div className="week-list">
          <span onClick={() => changeWeek("prev")}>
            <FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon>
          </span>
          <div>WEEK {currentWeek}</div>
          <span onClick={() => changeWeek("next")}>
            <FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon>
          </span>
        </div>
        <div className="game-results">
          {/* <ResultsViewByWeek week={currentWeek.toString()} league={'d1-sunday-league'}/> */}
          {games &&
          games.map((game, index) => (
          <EventBoxMinimal
              game={game}
              league={currentLeague}
              teams={teams}
              gameResults={gameResults.find((x) => x.id === game.id)}
              hasResults={gameResults.some((x) => x.id === game.id)}
          ></EventBoxMinimal>
          ))}        
        </div>
        <h2>STANDINGS</h2>
        {/* <div className="league-list">
          <span onClick={() => changeLeague()}>
            <FontAwesomeIcon icon={faCaretLeft}></FontAwesomeIcon>
          </span>
          <div>{currentLeague}</div>
          <span onClick={() => changeLeague()}>
            <FontAwesomeIcon icon={faCaretRight}></FontAwesomeIcon>
          </span>
        </div> */}
        <div className="home-standings">
        {teams.map((documents, index) => (
            <div className="standing-row" key={documents.id}>
              <div className="team">
                <div>{index + 1}.</div>
                <div><a href={`/teams/${documents.id}`}>{documents.id}</a></div>
              </div>
              <div className="standing">{documents.value["Wins"]} - {documents.value["Losses"]}</div>
              <div className="filler"></div>
            </div>
          ))}        
          </div>
        {/* <div className="big-division-info--container d1">
          <h2 className="big-division-info--header">D1 PRO AM SUNDAY LEAGUE</h2>
          <div className="big-division-info--fees">
            <div className="big-division-info--fees-fees">
              <div className="big-division-info--fees-fee">$950</div>
              <div className="big-division-info--fees-disclaimer">
                (REF FEES NOT INCLUDED)
              </div>
            </div>
            <div className="big-division-info--register">
              <a href="/d1-sunday-league-registration">
                <div className="btn-yellow">REGISTER</div>
              </a>
            </div>
          </div>
          <div className="big-division-info--format">
            <h2>LEAGUE FORMAT</h2>
            <div className="big-division-info--format-info">
              <div>12 TEAM LEAGUE</div>
              <div>11 GAME SEASON + PLAYOFFS</div>
              <div>TOP 8 TEAMS MAKE PLAYOFFS</div>
              <div>FOUR 12 MINUTE QUARTERS</div>
              <div>
                RUNNING GAME CLOCK
                <div className="small">
                  (CLOCK STOPS IN LAST 2 MINUTES IF SCORE IS WITHIN 10 POINTS)
                </div>
              </div>
              <div>LEAGUE WINNERS GET CHAMPIONSHIP RINGS</div>
              <div>NEXT SEASON FREE, AND MECCA APPAREL</div>
            </div>
          </div>
        </div>
        <div className="big-division-info--container d2">
          <h2 className="big-division-info--header">D2 SUNDAY LEAGUE</h2>
          <div className="big-division-info--fees">
            <div className="big-division-info--fees-fees">
              <div className="big-division-info--fees-fee">$600</div>
              <div className="big-division-info--fees-disclaimer">
                (REF FEES NOT INCLUDED)
              </div>
            </div>
            <div className="big-division-info--register">
              <a href="/d2-sunday-league-registration">
                <div className="btn-yellow">REGISTER</div>
              </a>
            </div>
          </div>
          <div className="big-division-info--format">
            <h2>LEAGUE FORMAT</h2>
            <div className="big-division-info--format-info">
              <div>8-16 TEAM LEAGUE</div>
              <div>11 GAME SEASON + PLAYOFFS</div>
              <div>TOP 8 TEAMS MAKE PLAYOFFS</div>
              <div>FOUR 10 MINUTE QUARTERS</div>
              <div>
                RUNNING GAME CLOCK
                <div className="small">
                  (CLOCK STOPS IN LAST 2 MINUTES IF SCORE IS WITHIN 10 POINTS)
                </div>
              </div>
              <div>LEAGUE WINNERS GET</div>
              <div>CHAMPIONSHIP RINGS + APPAREL</div>
            </div>
          </div>
        </div>
        <div className="big-division-info--container free-agent">
          <h2 className="big-division-info--header">THE MECCA FREE AGENCY</h2>
          <div className="big-division-info--fees">
            <div className="big-division-info--fees-fees">
              <div className="big-division-info--fees-fee">$50</div>
            </div>
            <div className="big-division-info--register">
              <a href="/d1-sunday-league-free-agent-registration">
                <div className="btn-yellow">D1 REGISTER</div>
              </a>
              <a href="/d2-sunday-league-free-agent-registration">
                <div className="btn-yellow">D2 REGISTER</div>
              </a>
            </div>
          </div>
          <div className="big-division-info--format">
            <h2>GUIDELINES</h2>
            <div className="big-division-info--format-info">
              <p>REGISTER AS A FREE AGENT FOR A
              CHANCE TO BE PICKED UP BY A TEAM IN
              THE MECCA. UPON GETTING SELECTED,
              THE LEAGUE COMMISSIONER WILL
              CONTACT YOU FOR PAYMENT AND LET
              YOU KNOW IF ADDITIONAL FEES ARE
              REQUIRED BY A TEAM CAPTAIN</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
