import React from "react";
import styled from "styled-components";
import meccaLogo from '../../images/logo.svg';

export function Logo(props) {
  return (
    <div className="logo-wrapper">
      <div className="logo-text">
        <a href="/#">The Mecca Pro-Am</a>
      </div>
    </div>
  );
}
