import React from "react";
import {db} from "../Firebase";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";

export const useGetAllTimeRecords = (league) => {
  console.log('_log useGetAllTimeRecords league: ', league)
  const [documents, setDocuments] = React.useState([]);
  const [assists, setAssists] = React.useState([]);
  const [blocks, setBlocks] = React.useState([]);
  const [points, setPoints] = React.useState([]);
  const [rebounds, setRebounds] = React.useState([]);
  const [steals, setSteals] = React.useState([]);
  React.useEffect(() => {
    db.collection('AllTimeRecords')
      .doc(league)
      .collection("3's")
      .get()
      .then((querySnapshot) => {
        console.log('_log query: ', querySnapshot)
        let arr = [];
        querySnapshot.docs.map((doc) =>
          arr.push({ id: doc.id, value: doc.data() })
        );
        setDocuments(arr);
      });
  }, []);
  React.useEffect(() => {
    db.collection('AllTimeRecords')
      .doc(league)
      .collection("Assists")
      .get()
      .then((querySnapshot) => {
        console.log('_log query: ', querySnapshot)
        let arr = [];
        querySnapshot.docs.map((doc) =>
          arr.push({ id: doc.id, value: doc.data() })
        );
        setAssists(arr);
      });
  }, []);
  React.useEffect(() => {
    db.collection('AllTimeRecords')
      .doc(league)
      .collection("Blocks")
      .get()
      .then((querySnapshot) => {
        console.log('_log query: ', querySnapshot)
        let arr = [];
        querySnapshot.docs.map((doc) =>
          arr.push({ id: doc.id, value: doc.data() })
        );
        setBlocks(arr);
      });
  }, []);
  React.useEffect(() => {
    db.collection('AllTimeRecords')
      .doc(league)
      .collection("Points")
      .get()
      .then((querySnapshot) => {
        console.log('_log query: ', querySnapshot)
        let arr = [];
        querySnapshot.docs.map((doc) =>
          arr.push({ id: doc.id, value: doc.data() })
        );
        setPoints(arr);
      });
  }, []);
  React.useEffect(() => {
    db.collection('AllTimeRecords')
      .doc(league)
      .collection("Rebounds")
      .get()
      .then((querySnapshot) => {
        console.log('_log query: ', querySnapshot)
        let arr = [];
        querySnapshot.docs.map((doc) =>
          arr.push({ id: doc.id, value: doc.data() })
        );
        setRebounds(arr);
      });
  }, []);
  React.useEffect(() => {
    db.collection('AllTimeRecords')
      .doc(league)
      .collection("Steals")
      .get()
      .then((querySnapshot) => {
        console.log('_log query: ', querySnapshot)
        let arr = [];
        querySnapshot.docs.map((doc) =>
          arr.push({ id: doc.id, value: doc.data() })
        );
        setSteals(arr);
      });
  }, []);
  console.log('_log documents: ', documents, assists, blocks, points, rebounds, steals)
  
  return [documents, assists, blocks, points, rebounds, steals]
};
