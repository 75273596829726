import { useState, useRef } from "react";
import "../../pages/Registration/registration.css"
import emailjs from "@emailjs/browser";
import {db} from "../../Firebase";
import { doc, Firestore, setDoc } from "firebase/firestore";
import { useGetFreeAgentResults } from "../../hooks/useGetFreeAgentResults";
import { useParams } from "react-router-dom";

const FreeAgentList = () => {
  const params = useParams()
const [freeAgentList] = useGetFreeAgentResults(params.league)
  return (
    <div className="page registration">
      <h2>Free Agent List</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>Height</th>
            <th>Instagram</th>
          </tr>
        </thead>
        <tbody>
        {freeAgentList && freeAgentList.length > 0 && freeAgentList.map((item) => (
            <tr key={item.id}>
              <td>{item.value['First Name']} {item.value['Last Name']}</td>
              <td>{item.value['Position']}</td>
              <td>{item.value['Height']}</td>
              <td>{item.value['Instagram']}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FreeAgentList;
