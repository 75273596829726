import React from "react";

const Memberships = () => {
  return (
    <div className="page memberships">
      <h2>Memberships</h2>
      <div>Coming Soon</div>
    </div>
  );
};

export default Memberships;
