import React from 'react';
import './contact.css';

const Contact = () => {

    return (
        <div className="page contact">
            <h2>Contact Us</h2>
            <div className="contact-text"><h3>Owner: </h3><span>Stephen Almquist</span></div>
            <div className="contact-text"><h3>Phone: </h3><span><a href="tel:+19492078614">(949) 207-8614</a></span></div>
            <div className="contact-text"><h3>Email: </h3><span><a href="mailto:themeccahoops@gmail.com">themeccahoops@gmail.com</a></span></div>
        </div>
    )
}

export default Contact;