import React, { useState } from "react";
import "./about.css";

export default () => {
  const [show, setShow] = useState();

  return (
    <div className="page">
      <ul>
        <li onClick={() => setShow("overview")}>Overview</li>
        <li onClick={() => setShow("leagueRules")}>League Rules</li>
        <li onClick={() => setShow("playerAgreement")}>Player Agreement</li>
      </ul>
      <div className="page">
        {show === "overview" && (
          <div id="overview" className={`overview ${show ? "show" : "hide"}`}>
            <p>
              Founded in 2022, Express Basketball is a year-round adult
              basketball league located in Orange County California.
            </p>
            <h3>LEAGUE DIRECTORS</h3>
            <p>Stephen Almquist & Sean Mitchell</p>
            <p>
              <a
                href="mailto:Expressbasketballoc@gmail.com"
                target="_blank"
                rel="noopener"
              >
                Expressbasketballoc@gmail.com
              </a>
            </p>
            <p>
              <span>949-207-8614</span>
            </p>
          </div>
        )}
        {show === "leagueRules" && (
          <div
            id="leagueRules"
            className={`league-rules ${show ? "show" : "hide"}`}
          >
            <p>
              <strong>D1 GAME FORMAT</strong>
              <br></br>
              -Player stats<br></br>
              -High school rules<br></br>
              -(4) 12 minute quarters<br></br>
              -Running clock<br></br>
              (Clock stops in last 2 mins if score is within 10 points)
            </p>
            {/* <p>
              <strong>D2 GAME FORMAT</strong>
              <br></br>
              -High school rules<br></br>
              -(4) 10 minute quarters<br></br>
              -Running clock<br></br>
              (Clock stops in last 2 mins if score is within 10 points)
            </p> */}
            <p>
              <strong>SUBSTITUTIONS</strong>
              <br></br>A team must play with a minimum of 4 players or the game
              will result in a forfeit. Teams can sub in any available players
              with the permission of the opposing team captain.
            </p>
            <p>
              <strong>JERSEYS</strong>
              <br></br>
              Players are required to wear numbered reversible jerseys or
              shirts. Players without a number or the same number as a teammate
              will result in a team technical foul.
            </p>
            <p>
              <strong>ROSTERS</strong>
              <br></br>
              Teams can have a maximum of 12 players on their roster. Players
              will have to play in 3 games to be eligible for playoffs. All
              Rosters must be finalized before the start of the season. Players
              must play with the same team throughout the season.
            </p>
            <p>
              <strong>ELIGIBILITY</strong>
              <br></br>
              To participate in Express Basketball League you must be 18 years
              of age or older.
            </p>
            <p>
              <strong>FORFEIT POLICY</strong>
              <br></br>
              Game clock starts running right at game time. Teams must have four
              or more players ready to start the game, and until they do the
              opposing team will receive a point for every minute past the start
              time.
              <br></br>
              <br></br>
              If a team is not ready by the end of the first quarter, it will
              result in a forfeit.
              <br></br>
              <br></br>
              If a team happens to forfeit, that team will be charged $75 which
              covers ref fees and scorekeepers that were reserved for that game.
              All fines must be paid prior to the start of the next game or your
              team will have to forfeit every week until that fine is paid.
            </p>
          </div>
        )}
        {show === "playerAgreement" && (
          <div
            id="playerAgreement"
            className={`player-agreement ${show ? "show" : "hide"}`}
          >
            <p>
              <strong>PLAYER AGREEMENT PLAYER INJURY WAIVER</strong>
              <br></br>
              <div>
                In consideration of being allowed to participate in any way in
                (EXPRESS BASKETBALL LEAGUE) athletic sports program, related
                events and activities, i acknowledge, appreciate, and agree
                that:
              </div>
              <div>
                1. The risks of injury and illness (ex: communicable diseases
                such as MRSA, influenza, and COVID-19) from the activities
                involved in this program are significant, including the
                potential for permanent paralysis and death, and while
                particular rules, equipment, and personal discipline may reduce
                these risks, the risks of serious injury and illness do exist;
                and,
              </div>
              <div>
                2. I KNOWINGLY AND FREELY ASSUME ALL SUCH RISKS, both known and
                unknown, EVEN IF ARISING FROM THE NEGLIGENCE OF THE RELEASEES or
                others, and assume full responsibility for my participation;
                and,
              </div>
              <div>
                3. I willingly agree to comply with the stated and customary
                terms and conditions for participation. If, however, I observe
                any unusual significant hazard during my presence or
                participation, I will remove myself from participation and bring
                such to the attention of the nearest official immediately; and,
              </div>
              <div>
                4. I, for myself and on behalf of my heirs, assigns, personal
                representatives and next of kin, HEREBY RELEASE AND HOLD
                HARMLESS (EXPRESS BASKETBALL LEAGUE) their officers, officials,
                agents, and/or employees, other participants, sponsoring
                agencies, sponsors, advertisers, and if applicable, owners and
                lessors of premises used to conduct the event (“RELEASEES”),
                WITH RESPECT TO ANY AND ALL INJURY, ILLNESS, DISABILITY, DEATH,
                or loss or damage to person or property, WHETHER ARISING FROM
                THE NEGLIGENCE OF THE RELEASEES OR OTHERWISE, to the fullest
                extent permitted by law.
              </div>
            </p>
            <p>
              <strong>PHOTO/VIDEO RELEASE</strong>
              <br></br> I hereby grant Express Basketball League permission to
              the rights of my image, likeness and sound of my voice as recorded
              on audio or video tape without payment or any other consideration.
              I understand that my image may be edited, copied, exhibited,
              published or distributed and waive the right to inspect or approve
              the finished product wherein my likeness appears. Additionally, I
              waive any right to royalties or other compensation arising or
              related to the use of my image or recording. I also understand
              that this material may be used in advertisements, displays,
              pamphlets/brochures, and on league website.
            </p>
            <p>
              <strong>PLAYER CONDUCT</strong>
              <br></br> -All player are to conduct themselves in a appropriate
              and sportsmanlike manner -No player has the right to physically or
              verbally abuse officials, scorekeepers, or other players -The
              captain is responsible for the action of his teammates. Should
              anyone on the team get in a fight, that player will be banned for
              the rest of the season.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
