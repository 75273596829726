import React from "react";

const Exhibitions = () => {
  return (
    <div className="page exhibitions">
      <h2>Exhibitions</h2>
      <div>Coming Soon</div>
    </div>
  );
};

export default Exhibitions;
