import React, {useState} from "react";
import {logout, createAccount, loginEmailPassword, db} from '../Firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, Firestore, setDoc } from "firebase/firestore";

const auth = getAuth();

const Signup = () => {
  let user = localStorage.getItem('User');
  user = JSON.parse(user)
  const [signupForm, setSignupForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    team: '',
    league: 'd1-sunday-league'
  });

  const handleChange = (event) => {
    setSignupForm({ ...signupForm, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createAccount(signupForm.email, signupForm.password, signupForm.firstName, signupForm.lastName, signupForm.phoneNumber, signupForm.team, true, signupForm.league.toString(), 3)
    setSignupForm({ firstName: '',lastName: '',email: '',password: '',phoneNumber: '',team: '',league: '' });
  };
  
  return (
    <div className="page signup">
      <h2>Sign Up</h2>
      <form className="submit-form" onSubmit={handleSubmit}>
        <div><label>First Name<input required type="text" name="firstName" value={signupForm.firstName} onChange={handleChange}></input></label></div>
        <div><label>Last Name<input required type="text" name="lastName" value={signupForm.lastName} onChange={handleChange}></input></label></div>
        <div><label>Team<input required type="text" name="team" value={signupForm.team} onChange={handleChange}></input></label></div>
        <div className="league-radio-container">
          <div className="league-radio">
            <label htmlFor="d1SundayLeague">D1 Sunday League</label>
            <input required type="radio" id="d1SundayLeague" name="league" value="d1-sunday-league" onChange={handleChange}/>
          </div>
          {/* <div className="league-radio">
            <label htmlFor="d2SundayLeague">D2 Sunday League</label>
            <input required type="radio" id="d2SundayLeague" name="league" value="d2-sunday-league" onChange={handleChange}/>
          </div> */}
        </div>
        <div><label>Phone Number<input required type="text" name="phoneNumber" value={signupForm.phoneNumber} onChange={handleChange}></input></label></div>
        <div><label>Email<input required type="text" name="email" value={signupForm.email} onChange={handleChange}></input></label></div>
        <div><label>Password<input required type="text" name="password" value={signupForm.password} onChange={handleChange}></input></label></div>
        <input required type="submit" className="btn-yellow" value="Submit" />
      </form>
    </div>
  );
};

export default Signup;
