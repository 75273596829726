import React, { useState } from "react";
import { useGetCollectionData } from "../../hooks/useGetCollectionData";
import "./resultsbyweek.css";
import "../../App.css";
import { useGetGamesByWeek } from "../../hooks/useGetGamesByWeek";
import { useGetGameResults } from "../../hooks/useGetGameResults";
import { useGetGameResultsByWeek } from "../../hooks/useGetGameResultsByWeek";
import EventBox from "./EventBox";
import { useParams } from "react-router-dom";

export default (props) => {
  let p = props
  let {paramsLeague, paramsWeek} = useParams();
  console.log('_log params: ', paramsLeague, paramsWeek, p.week)
  let week = paramsWeek || p.week || 0
  let league = paramsLeague || p.league || 'd1-sunday-league'
  console.log('_log week: ', week)
  console.log('_log league: ', league)
  let [games] = useGetGamesByWeek(1, 'd1-sunday-league');
  let [gameResults] = useGetGameResultsByWeek(1);
console.log('_log games: ', games)
console.log('_log gameResults: ', gameResults)
  return (
    <div className="results-by-week page ">
    {games &&
        games.map((game, index) => (
        <EventBox
            game={game}
            league={league}
            gameResults={gameResults.find((x) => x.id === game.id)}
            hasResults={gameResults.some((x) => x.id === game.id)}
        ></EventBox>
        ))}
    </div>
  );
};
