import React from 'react';
import '../../App.css';
import './faq.css'

const FAQ = () => {

    return (
        <div className="faq page">
            <h2>FAQ</h2>
            <p>
                Q: How many games are there in a season?<br></br>
                A: 11 game season plus playoffs</p>
            <p>
                Q: How many teams make playoffs?<br></br>
                A: Top 8 teams make playoffs </p>
            <p>
                Q: Where are the games played?<br></br>
                A: D1 is at Calvary Chapel Costa Mesa</p>
            <p>
                Q: How much is it to join The Mecca?<br></br>
                A: D1 team fees are $675 (Ref fees not included)</p>
            <p>
                Q: How much are ref fees?<br></br>
                A: D1 ref fees are $45 per game</p>
            <p>
                Q: How much is it to join The Mecca as a Free Agent?<br></br>
                A: $100 for 11 games (including playoffs)</p>
            <p>
                Q: What do top stat leaders and MVP get?<br></br>
                A: D1 stat leaders will receive a trophy and league MVP will receive $1,000 </p>
            <p>
                Q: What do we get for winning the league?<br></br>
                A: D1 league winners receive $2,500 prize</p>
        </div>
    )
}

export default FAQ;