import React from "react";
import {db} from "../Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  documentId,
  FieldPath,
  getDoc,
} from "firebase/firestore";

export const useGetGameResultsByWeek = (week) => {
  const [documents, setDocuments] = React.useState([]);

  const q = query(collection(db, "GameResults"), where("Week", "==", week));
  React.useEffect(() => {
    getDocs(q).then((querySnapshot) => {
      let arr = [];
      querySnapshot.docs.map((doc) =>
        arr.push({ id: doc.id, value: doc.data() })
      );
      setDocuments(arr);
    });
  }, [week]);
  return [documents];
};
