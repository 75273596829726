import React from "react";
import { useParams } from "react-router-dom";
import { useGetAllTimeStats } from "../../hooks/useGetAllTimeStats";
import { useGetStats } from "../../hooks/useGetStats";

const AllAllTimeStatsLeaders = () => {
  const params = useParams();
  const splitParam = params.stat.split("-");
  const [players] = useGetAllTimeStats(params.league);
  const statLeaders = players.sort(
    (a, b) =>
      parseInt(b.value[splitParam[1]]) - parseInt(a.value[splitParam[1]])
  );
  return (
    <div className="page stats">
      <h3>{splitParam[0]} Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            {/* <th>Team</th> */}
            <th>{splitParam[1]}</th>
          </tr>
        </thead>
        <tbody>
          {statLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              {/* <td>{documents.value["Team"]}</td> */}
              <td>{documents.value[splitParam[1]]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllAllTimeStatsLeaders;
