import React from "react";
import { a } from "react-router-dom";
import styled from "styled-components";
import "./navLinks.css";

const NavLinksContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const LinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
`;
const SubLinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
  display: none;
`;

const LinkItem = styled.li`
  height: 100%;
  padding: 0 1.1em;
  color: #222;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-top: 2px solid transparent;
  transition: all 220ms ease-in-out;
  &:hover {
    border-top: 2px solid #2ecc71;
  }
`;
const SubLinkItem = styled.li`
  height: 100%;
  padding: 0 1.1em;
  color: #222;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-top: 2px solid transparent;
  transition: all 220ms ease-in-out;
  &:hover {
    border-top: 2px solid #2ecc71;
  }
`;

// const a = styled.a`
//   text-decoration: none;
//   color: inherit;
//   font-size: inherit;
// `;

export function NavLinks(props) {
  return (
    <div className="navlinks--container">
      <ul className="navlinks--links-wrapper">
        <li className="navlinks--link">
          <a href="/">Home</a>
        </li>
        <li className="navlinks--link">
          <a href="/registration">Registration</a>
        </li>
        <li className="navlinks--link">
          <a href="/d1-sunday-league">D1 Sunday League</a>
          <ul className="navlinks--sublink-wrapper">
            <li className="navlinks--sublink">
              <a href="/d1-sunday-league/standings">Standings</a>
            </li>
            <li className="navlinks--sublink">
              <a href="/d1-sunday-league/results">Results</a>
            </li>
          </ul>
        </li>
        {/* <li className="navlinks--link">
          <a href="/d2-sunday-league">D2 Sunday League</a>
        </li> */}
        <li className="navlinks--link">
          <a href="/about">About</a>
        </li>
      </ul>
    </div>
  );
}
