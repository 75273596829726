import React from "react";
import { useGetGameResults } from "../../hooks/useGetGameResults";
import { useParams } from "react-router-dom";
import "./results.css";
import "../../App.css";

const GameResults = () => {
  const data = useParams();
  let paramTeams = data.id.split('-')[1].split('|')
  const [teams, players] = useGetGameResults(data.id);

  let playersSplitByTeam =
    players.length > 0 &&
    players.reduce(function (obj, value) {
      let key = value.value.Team;
      if (obj[key] == null) obj[key] = [];
      if (key == paramTeams[0]) {
        obj[key].push(value);
      } else if (key == paramTeams[1]) {
        obj[key].push(value);
      }
      return obj;
    }, {});

    let team1header = teams.length > 0 && paramTeams[0] + ' - ' + teams.find(x => x.id === paramTeams[0]).value.Score
    let team2header = teams.length > 0 && paramTeams[1] + ' - ' + teams.find(x => x.id === paramTeams[1]).value.Score
  return (
    <div className="page game-results">
      <h3 className={teams.length > 0 ? teams[0].value["Outcome"] : undefined}>
        {team1header}
      </h3>
      <table>
        <thead>
          <tr>
            <th>Player</th>
            <th>FT</th>
            <th>FTA</th>
            <th>FG</th>
            <th>FGA</th>
            <th>3PT</th>
            <th>3PTA</th>
            <th>AS</th>
            <th>ST</th>
            <th>BK</th>
            <th>RB</th>
            <th>TP</th>
          </tr>
        </thead>
        <tbody>
          {playersSplitByTeam &&
            playersSplitByTeam[paramTeams[0]].map((p) => (
              <tr>
                <td className="teamname">{p["id"]}</td>
                {p["value"][data.id].map((d) => (
                  <td>{d}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      <br></br>
      <h3 className={teams.length > 0 ? teams[1].value["Outcome"] : undefined}>
        {team2header}
      </h3>
      <table>
        <thead>
          <tr>
            <th>Player</th>
            <th>FT</th>
            <th>FTA</th>
            <th>FG</th>
            <th>FGA</th>
            <th>3PT</th>
            <th>3PTA</th>
            <th>AS</th>
            <th>ST</th>
            <th>BK</th>
            <th>RB</th>
            <th>TP</th>
          </tr>
        </thead>
        <tbody>
          {playersSplitByTeam &&
            playersSplitByTeam[paramTeams[1]].map((p) => (
              <tr>
                <td className="teamname">{p.id}</td>
                {p.value[data.id].map((d) => (
                  <td>{d}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default GameResults;