import React from "react";
import { useGetAllTimeRecords } from "../../hooks/useGetAllTimeRecords";
import "./records.css";
import { useParams } from "react-router-dom";

const Records = () => {
  const params = useParams()
  const [TPT, AS, BK, TP, RB, ST] = useGetAllTimeRecords('d1-sunday-league');
  console.log('_log records: ', TPT, AS, BK, TP, RB, ST)

  return (
    <div className="page records">
      <h2>All Time Records</h2>
        <h3>Championships</h3>
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Team</th>
              <th>Wins</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      {TPT.length > 0 &&
        (
          <>
            <h3>Single Game {TPT[0].value.id}</h3>
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Player</th>
                  <th>{TPT[0].value.id}</th>
                </tr>
              </thead>
              <tbody>
                {TPT[0].value["Players"].map((player, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{player.Name}</td>
                    <td>{player.Score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {AS.length > 0 &&
        (
          <>
            <h3>Single Game {AS[0].value.id}</h3>
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Player</th>
                  <th>{AS[0].value.id}</th>
                </tr>
              </thead>
              <tbody>
                {AS[0].value["Players"].map((player, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{player.Name}</td>
                    <td>{player.Score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {BK.length > 0 &&
        (
          <>
            <h3>Single Game {BK[0].value.id}</h3>
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Player</th>
                  <th>{BK[0].value.id}</th>
                </tr>
              </thead>
              <tbody>
                {BK[0].value["Players"].map((player, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{player.Name}</td>
                    <td>{player.Score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {TP.length > 0 &&
        (
          <>
            <h3>Single Game {TP[0].value.id}</h3>
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Player</th>
                  <th>{TP[0].value.id}</th>
                </tr>
              </thead>
              <tbody>
                {TP[0].value["Players"].map((player, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{player.Name}</td>
                    <td>{player.Score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {RB.length > 0 &&
        (
          <>
            <h3>Single Game {RB[0].value.id}</h3>
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Player</th>
                  <th>{RB[0].value.id}</th>
                </tr>
              </thead>
              <tbody>
                {RB[0].value["Players"].map((player, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{player.Name}</td>
                    <td>{player.Score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {ST.length > 0 &&
        (
          <>
            <h3>Single Game {ST[0].value.id}</h3>
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Player</th>
                  <th>{ST[0].value.id}</th>
                </tr>
              </thead>
              <tbody>
                {ST[0].value["Players"].map((player, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{player.Name}</td>
                    <td>{player.Score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
    </div>
  );
};

export default Records;
