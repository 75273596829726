import "./App.css";
import Routing from "./routing/Routing.js";
import Footer from "./pages/Home/Footer";

function App() {
  return (
    <div className="App">
      <Routing></Routing>
      <Footer></Footer>
    </div>
  );
}

export default App;
