
import React ,{useState} from 'react';
import AWS from 'aws-sdk'
import { getQueriesForElement } from '@testing-library/react';

const S3_BUCKET ='themeccahoops-potw';
const REGION ='us-east-1';


AWS.config.update({
    accessKeyId: 'AKIATK4DJC7IBBYOSMVI',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

const getImage = (src) => {
    const params = {
        Bucket: S3_BUCKET,
        Key: src+'.jpeg'
    }

    myBucket.getObject(params)
        .send((err, data) => {
            if (err) {
                console.log("_log ERROR on getObject: ", err)
                return false
            }
            console.log('_log SUCCESS on getObject: ', data)
            let imgSrc = "data:image/jpeg;base64," + encode(data.Body);
            return imgSrc
        })
}
function encode(data) {
    var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
    return window.btoa(str).replace(/.{76}(?=.)/g,'$&\n');
}
function encode2(data){
    var binary = '';
    var bytes = new Uint8Array( data );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

export default getImage;