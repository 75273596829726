import React from "react";
import "../../App.css";
import "./results.css"
import { useParams } from "react-router-dom";

export default () => {
  const params = useParams()
  let weeks;
  if(params.league == 'd1-sunday-league'){
    weeks = ['1/15/23','1/22/23','1/29/23','2/05/23','2/19/23','2/26/23','3/5/23','3/12/23','3/19/23','3/26/23','4/2/23']
  }
  // else if(params.league == 'd2-sunday-league'){
  //   weeks = ['1/15/23','1/22/23','1/29/23','2/19/23','2/26/23','3/5/23','3/12/23','3/19/23','3/26/23']
  // }
  
  let baseUrl = '/results/'
  return (
    <div className="results page menu">
      <h2>Schedule & Results</h2>
      <ul className="main-menu">
        {weeks.map((x, index) => <li className="menu-item"><a href={baseUrl + params.league + '/' + (index+1)}>Week {index + 1} - {x}</a></li>)}
        
      </ul>
    </div>
  );
};
