import React from "react";
import { useGetTeam } from "../../hooks/useGetTeam";
import "./team.css";
import { useParams } from "react-router-dom";

export default () => {
  const params = useParams();
  const team = useGetTeam(params.team);

  return (
    <div className="page standings">
      <h2>{params.team} Roster</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {team && team.length > 0 && team.map((player, index) => (
            <tr key={player.id}>
              <td>{player.id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
