import React from "react";
import {db} from "../Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  documentId,
  FieldPath,
  getDoc,
} from "firebase/firestore";

export const useGetFreeAgentResults = (division) => {
  const [freeAgentList, setFreeAgentList] = React.useState([]);

  React.useEffect(() => {
    db.collection("FreeAgent")
    .doc(division)
    .collection("Agents")
    .get()
    .then((snapshot) => {
        let arr = [];
        snapshot.docs.map((doc) => arr.push({ id: doc.id, value: doc.data() }));
        setFreeAgentList(arr);
    });
  }, [])
  return [freeAgentList];
};
