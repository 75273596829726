import React from 'react';
import './feedback.css';

const Feedback = () => {
    return (
      <div className="page feedback">
        <h2>Give us your feedback!</h2>
        <form>
          <div><textarea 
          rows={5}
          cols={50}></textarea></div>
          <br></br>
          <div>Submit</div>
        </form>
      </div>
    );
  };
  
  export default Feedback;
  