import { useState, useRef } from "react";
import "./registration.css";
import emailjs from "@emailjs/browser";
import {logout, createAccount, loginEmailPassword, db} from '../../Firebase';

const D1SundayLeagueRegistration = () => {
  const form = useRef();

  //NOTE: When using EmailJS, combine all player information
  //into one big string so it can be sent correctly
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_8f2pqna",
        "template_dl995f4",
        form.current,
        "t-FZ46K78lk_GTwLu"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Your email has been sent.");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="page registration">
      <h2>Registration</h2>
      <form ref={form} onSubmit={handleSubmit}>
        <input hidden name="division" value="Division 1" />
        <ul className="form-style-1">
          <li className="form-element">
            <label htmlFor="teamName">Team Name</label>
            <input id="teamName" name="teamname" type="text" required></input>
          </li>
          <li className="form-element">
            <label htmlFor="captainFirstName">Captain/Coach First Name</label>
            <input
              id="captainFirstName"
              name="captainFirstName"
              type="text"
              required
            ></input>
          </li>
          <li className="form-element">
            <label htmlFor="captainLastName">Captain/Coach Last Name</label>
            <input
              id="captainLastName"
              name="captainLastName"
              type="text"
              required
            ></input>
          </li>
          <li className="form-element">
            <label htmlFor="captainPhone">Captain/Coach Phone Number</label>
            <input
              id="captainPhone"
              name="captainPhone"
              type="text"
              required
            ></input>
          </li>
          <li className="form-element">
            <label htmlFor="captainEmail">Captain/Coach Email</label>
            <input
              id="captainEmail"
              name="captainEmail"
              type="text"
              required
            ></input>
          </li>
          <li className="form-element">
            <label htmlFor="captainInsta">Captain/Coach Instagram (optional)</label>
            <input
              id="captainInsta"
              name="captainInsta"
              type="text"
            ></input>
          </li>
          <li>
            <input type="submit" value="Submit" />
          </li>
        </ul>
      </form>
    </div>
  );
};

export default D1SundayLeagueRegistration;
