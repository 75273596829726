import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./routing-styles.css";
import "../pages/Home/footer.css";
import Home from "../pages/Home/Home";
import D1SundayLeague from "../pages/Leagues/D1SundayLeague";
// import D2SundayLeague from "../pages/Leagues/D2SundayLeague";
import Registration from "../pages/Registration";
import D1SundayLeagueRegistration from "../pages/Registration/D1SundayLeagueRegistration";
import D1SundayLeagueWaitlistRegistration from "../pages/Registration/D1SundayLeagueWaitlistRegistration";
import Standings from "../pages/Standings/Standings";
import Results from "../pages/Results/Results";
import GameResults from "../pages/Results/GameResults";
import About from "../pages/About/About";
import Leagues from "../pages/Leagues/Leagues";
import Admin from "../components/Admin";
import NavbarHeader from "../components/navbar/NavbarHeader";
import Exhibitions from "../pages/Exhibitions";
import OpenRuns from "../pages/OpenRuns";
import Memberships from "../pages/Memberships";
import Account from "../pages/Account";
import Signup from "../pages/Signup";
import Signin from "../pages/Signin";
import Stats from "../pages/Stats/Stats";
import Records from "../pages/Records/Records";
import D1SundayLeagueFreeAgentRegistration from "../pages/Registration/D1SundayLeagueFreeAgentRegistration";
// import D2SundayLeagueFreeAgentRegistration from "../pages/Registration/D2SundayLeagueFreeAgentRegistration";
// import D2SundayLeagueRegistration from "../pages/Registration/D2SundayLeagueRegistration";
// import D2SundayLeagueWaitlistRegistration from "../pages/Registration/D2SundayLeagueWaitlistRegistration";
import AllStats from "../pages/Stats/AllStats";
import Feedback from '../pages/Feedback/Feedback';
import ResultsViewByWeek from "../pages/Results/ResultsViewByWeek";
import Gallery from "../pages/Gallery/Gallery";
import AllTimeStatLeaders from "../pages/Stats/AllTimeStatsLeaders";
import D1SundayLeagueGallery from "../pages/Gallery/D1SundayLeagueGallery";
import Tournaments from "../pages/Tournaments/Tournaments";
import Team from "../pages/Team/Team";
import AllAllTimeStatsLeaders from "../pages/Stats/AllAllTimeStatsLeaders";
import FAQ from '../pages/FAQ/FAQ';
import Contact from "../pages/Contact/Contact";
import PlayoffBracket from "../pages/PlayoffBracket/PlayoffBracket";
import FreeAgentList from "../pages/D1 Sunday League/FreeAgentList";
import TempPage from "../pages/TempPage";
import Statistics from "../pages/Statistics/Statistics";

export default function Routing() {
  return (
    <Router>
      {/* <Navbar></Navbar> */}
      <NavbarHeader></NavbarHeader>
      {/* <Header></Header> */}
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/leagues" element={<Leagues />}></Route>
        <Route path="/registration" element={<D1SundayLeagueRegistration />}></Route>
        <Route path="/games" element={<TempPage />}></Route>
        <Route path="/statistics" element={<Statistics />}></Route>
        <Route path="/shop" element={<TempPage />}></Route>
        
        <Route
          path="/d1-sunday-league-registration"
          element={<D1SundayLeagueRegistration />}
        ></Route>
        <Route
          path="/d1-sunday-league-waitlist-registration"
          element={<D1SundayLeagueWaitlistRegistration />}
        ></Route>
        <Route
          path="/d1-sunday-league-free-agent-registration"
          element={<D1SundayLeagueFreeAgentRegistration />}
        ></Route>
        <Route
          path="/:league/free-agent-list"
          element={<FreeAgentList />}
        ></Route>
        {/* <Route
          path="/d2-sunday-league-registration"
          element={<D2SundayLeagueRegistration />}
        ></Route> */}
        {/* <Route
          path="/d2-sunday-league-waitlist-registration"
          element={<D2SundayLeagueWaitlistRegistration />}
        ></Route> */}
        {/* <Route
          path="/d2-sunday-league-free-agent-registration"
          element={<D2SundayLeagueFreeAgentRegistration />}
        ></Route> */}
        <Route path="/d1-sunday-league" element={<D1SundayLeague />}></Route>
        {/* <Route path="/d2-sunday-league" element={<D2SundayLeague />}></Route> */}
        <Route path="/about" element={<About />}></Route>
        <Route path="/standings/:league" element={<Standings />}></Route>
        <Route path="/d1-sunday-league/results" element={<Results />}></Route>
        <Route path="/results/:league" element={<Results />}></Route>
        <Route path="/results/:league/:week" element={<ResultsViewByWeek/>}></Route>
        <Route path="/:league/stats" element={<Stats />}></Route>
        <Route
          path="/:league/stats/:stat"
          element={<AllStats />}
        ></Route>
        <Route
          path="/:league/statsleaders/:stat"
          element={<AllAllTimeStatsLeaders />}
        ></Route>
        <Route path="/:league/all-time-stat-leaders" element={<AllTimeStatLeaders />}></Route>
        <Route path="/:league/records" element={<Records />}></Route>
        <Route path="/:league/:season/playoff-bracket" element={<PlayoffBracket />}></Route>
        <Route path="/game-results/:id" element={<GameResults />}></Route>
        <Route path="/game-results/:league/:id" element={<GameResults />}></Route>
        <Route path="/admin" element={<Admin />}></Route>
        <Route path="/exhibitions" element={<Exhibitions />}></Route>
        <Route path="/openruns" element={<OpenRuns />}></Route>
        <Route path="/memberships" element={<Memberships />}></Route>
        {/* <Route path="/account" element={<Account />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/signin" element={<Signin />}></Route> */}
        <Route path="/feedback" element={<Feedback />}></Route>
        <Route path="/gallery" element={<Gallery/>}></Route>
        <Route path="/tournaments" element={<Tournaments/>}></Route>
        <Route path="/teams/:team" element={<Team/>}></Route>
        <Route path="/faq" element={<FAQ/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route>
      </Routes>
    </Router>
  );
}
