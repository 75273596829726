
import React ,{useState} from 'react';
import AWS from 'aws-sdk'
import { getQueriesForElement } from '@testing-library/react';

const S3_BUCKET ='themeccahoops-potw';
const REGION ='us-east-1';


AWS.config.update({
    accessKeyId: 'AKIATK4DJC7IBBYOSMVI',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

const AWSS3 = () => {

    const [progress , setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [img, setImg] = useState()

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    // function myCtrl($scope, $timeout) {    
    //     AWS.config.update({
    //         accessKeyId: 'YOUR_ACCESS_TOKEN', 
    //         secretAccessKey: 'YOUR_SECRET'
    //     });
    //     AWS.config.region = "YOUR_REGION";
        
    //     var bucket = new AWS.S3({params: {Bucket: 'YOUR_BUCKET'}});
        
    //     bucket.getObject({Key: 'happy-face.jpg'}, function(err,file){
    //         $timeout(function(){
    //             $scope.s3url = "data:image/jpeg;base64," + encode(file.Body);
    //         }, 1);
    //     });
    // }
    
    // function encode(data) {
    //     var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
    //     return btoa(str).replace(/.{76}(?=.)/g,'$&\n');
    // }

    const uploadFile = (file) => {

        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: file.name,
            ContentType: 'image/jpeg'
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
                if (err) {
                    console.log(err)
                    alert(err)
                }
                alert("File uploaded successfully")
            })
    }


    return <div>
        <div>Native SDK File Upload Progress is {progress}%</div>
        <input type="file" onChange={handleFileInput}/>
        <button onClick={() => uploadFile(selectedFile)}> Upload to S3</button>
    </div>
}

export default AWSS3;