import React from "react";
import './tournaments.css';

const Tournaments = () => {
  return (
    <div className="tournaments page">
      <h2>tournaments</h2>
      <div>Coming Soon</div>
    </div>
  );
};

export default Tournaments;
