import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import "./footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <ul>
        {/* <li>
          <a href="/faq">FAQ</a>
        </li> */}
        {/* <li>
          <a href="">Join the Team</a>
        </li>
        <li>
          <a href="">Contact Us</a>
        </li> */}
        {/* <li>
          <a href="https://lindseymerriphoto.pixieset.com/themecca/">Gallery</a>
        </li> */}
        {/* <li>
          <a href="/feedback">Feedback</a>
        </li> */}
        <li className="icon-container">
          <a href="https://www.instagram.com/themeccahoops">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="tel:+19492078614">
            <FontAwesomeIcon icon={faPhone} />
          </a>
          <a href="mailto:themeccaproam@gmail.com">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
