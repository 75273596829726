import React from "react";
import { useGetTeams } from "../../hooks/useGetTeams";
import "./standings.css";
import { useParams } from "react-router-dom";

export default () => {
  const league = useParams();
  const [documents] = useGetTeams('d1-sunday-league');
  let teams = documents.sort((a, b) => b.value['Wins'] - a.value['Wins'])
  return (
    <div className="page standings">
      <h2>Standings</h2>

      <table>
        <thead>
          <tr>
            <th>POS</th>
            <th>Team</th>
            <th>Wins</th>
            <th>Losses</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((documents, index) => (
            <tr key={documents.id}>
              <td>{index + 1}</td>
              <td><a href={`/teams/${documents.id}`}>{documents.id}</a></td>
              <td>{documents.value["Wins"]}</td>
              <td>{documents.value["Losses"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
