import React from "react";
import { useGetStats } from "../../hooks/useGetStats";
import "./stats.css";
import { useParams } from "react-router-dom";

const Stats = () => {
  const params = useParams()
  const [stats] = useGetStats(params.league);
let pointLeaderStats = stats.filter(x => x.value['TP'])
  const pointLeaders = pointLeaderStats.sort(
    (a, b) => parseInt(b.value["TP"]) - parseInt(a.value["TP"])
  );
  let newPointLeaders = pointLeaders.slice(0, 5);
  
let threePointLeaderStats = stats.filter(x => x.value['TPT'])
  let threePointLeaders = threePointLeaderStats.sort(
    (a, b) => parseInt(b.value["TPT"]) - parseInt(a.value["TPT"])
  );
  threePointLeaders = threePointLeaders.slice(0, 5);

let assistLeaderStats = stats.filter(x => x.value['AS'])
  let assistLeaders = assistLeaderStats.sort(
    (a, b) => parseInt(b.value["AS"]) - parseInt(a.value["AS"])
  );
  assistLeaders = assistLeaders.slice(0, 5);

let stealLeaderStats = stats.filter(x => x.value['ST'])
  let stealLeaders = stealLeaderStats.sort(
    (a, b) => parseInt(b.value["ST"]) - parseInt(a.value["ST"])
  );
  stealLeaders = stealLeaders.slice(0, 5);

let blockLeaderStats = stats.filter(x => x.value['BK'])
  let blockLeaders = blockLeaderStats.sort(
    (a, b) => parseInt(b.value["BK"]) - parseInt(a.value["BK"])
  );
  blockLeaders = blockLeaders.slice(0, 5);

let reboundLeaderStats = stats.filter(x => x.value['BK'])
  let reboundLeaders = reboundLeaderStats.sort(
    (a, b) => parseInt(b.value["RB"]) - parseInt(a.value["RB"])
  );
  reboundLeaders = reboundLeaders.slice(0, 5);
  return (
    <div className="page stats">
      <h2>Stat Leaders</h2>
      <h3>Point Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>TP</th>
          </tr>
        </thead>
        <tbody>
          {newPointLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              <td>{documents.value["TP"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + params.league + '/stats/Points-TP'}>See More</a>
      <h3>3 Point Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>3PT</th>
          </tr>
        </thead>
        <tbody>
          {threePointLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              <td>{documents.value["TPT"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + params.league + "/stats/3 Point-TPT"}>See More</a>
      <h3>Assists Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>AS</th>
          </tr>
        </thead>
        <tbody>
          {assistLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              <td>{documents.value["AS"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + params.league + "/stats/Assists-AS"}>See More</a>
      <h3>Steal Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>ST</th>
          </tr>
        </thead>
        <tbody>
          {stealLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              <td>{documents.value["ST"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + params.league + "/stats/Steal-ST"}>See More</a>
      <h3>Block Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>BK</th>
          </tr>
        </thead>
        <tbody>
          {blockLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              <td>{documents.value["BK"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + params.league + "/stats/Block-BK"}>See More</a>
      <h3>Rebound Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>RB</th>
          </tr>
        </thead>
        <tbody>
          {reboundLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              <td>{documents.value["RB"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + params.league + "/stats/Rebound-RB"}>See More</a>
    </div>
  );
};

export default Stats;
