import React from "react";
import { useParams } from "react-router-dom";
import Stats from "../Stats/Stats";
import Records from "../Records/Records";
import { useGetAllTimeRecords } from "../../hooks/useGetAllTimeRecords";
import "../Records/records.css";
import { useGetStats } from "../../hooks/useGetStats";
import "../Stats/stats.css";

const Statistics = () => {
const params = useParams()
  const [stats] = useGetStats('d1-sunday-league');
let pointLeaderStats = stats.filter(x => x.value['TP'])
  const pointLeaders = pointLeaderStats.sort(
    (a, b) => parseInt(b.value["TP"]) - parseInt(a.value["TP"])
  );
  let newPointLeaders = pointLeaders.slice(0, 5);
  
let threePointLeaderStats = stats.filter(x => x.value['TPT'])
  let threePointLeaders = threePointLeaderStats.sort(
    (a, b) => parseInt(b.value["TPT"]) - parseInt(a.value["TPT"])
  );
  threePointLeaders = threePointLeaders.slice(0, 5);

let assistLeaderStats = stats.filter(x => x.value['AS'])
  let assistLeaders = assistLeaderStats.sort(
    (a, b) => parseInt(b.value["AS"]) - parseInt(a.value["AS"])
  );
  assistLeaders = assistLeaders.slice(0, 5);

let stealLeaderStats = stats.filter(x => x.value['ST'])
  let stealLeaders = stealLeaderStats.sort(
    (a, b) => parseInt(b.value["ST"]) - parseInt(a.value["ST"])
  );
  stealLeaders = stealLeaders.slice(0, 5);

let blockLeaderStats = stats.filter(x => x.value['BK'])
  let blockLeaders = blockLeaderStats.sort(
    (a, b) => parseInt(b.value["BK"]) - parseInt(a.value["BK"])
  );
  blockLeaders = blockLeaders.slice(0, 5);

let reboundLeaderStats = stats.filter(x => x.value['BK'])
  let reboundLeaders = reboundLeaderStats.sort(
    (a, b) => parseInt(b.value["RB"]) - parseInt(a.value["RB"])
  );
  reboundLeaders = reboundLeaders.slice(0, 5);
  const [TPT, AS, BK, TP, RB, ST] = useGetAllTimeRecords('d1-sunday-league');

  return (
    <>
    <div className="page stats">
      <h2>Stat Leaders</h2>
        <div className="record-container">
          <h3>Point Leaders</h3>
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Player</th>
                  {/* <th>Team</th> */}
                  <th>TP</th>
                </tr>
              </thead>
              <tbody>
                {newPointLeaders.map((documents, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td className="teamname">{documents.id}</td>
                    {/* <td className="teamname">{documents.value["Team"]}</td> */}
                    <td>{documents.value["TP"]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <a href={"/" + 'd1-sunday-league' + '/stats/Points-TP'}>See More</a>
        </div> 
        <div className="record-container">
      <h3>3 Point Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            {/* <th>Team</th> */}
            <th>3PT</th>
          </tr>
        </thead>
        <tbody>
          {threePointLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td className="teamname">{documents.id}</td>
              {/* <td className="teamname">{documents.value["Team"]}</td> */}
              <td>{documents.value["TPT"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + 'd1-sunday-league' + "/stats/3 Point-TPT"}>See More</a>
        </div>
        <div className="record-container">
      <h3>Assists Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            {/* <th>Team</th> */}
            <th>AS</th>
          </tr>
        </thead>
        <tbody>
          {assistLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td className="teamname">{documents.id}</td>
              {/* <td className="teamname">{documents.value["Team"]}</td> */}
              <td>{documents.value["AS"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + 'd1-sunday-league' + "/stats/Assists-AS"}>See More</a>
        </div>
        <div className="record-container">
      <h3>Steal Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            {/* <th>Team</th> */}
            <th>ST</th>
          </tr>
        </thead>
        <tbody>
          {stealLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td className="teamname">{documents.id}</td>
              {/* <td className="teamname">{documents.value["Team"]}</td> */}
              <td>{documents.value["ST"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + 'd1-sunday-league' + "/stats/Steal-ST"}>See More</a>
        </div>
        <div className="record-container">
      <h3>Block Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            {/* <th>Team</th> */}
            <th>BK</th>
          </tr>
        </thead>
        <tbody>
          {blockLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td className="teamname">{documents.id}</td>
              {/* <td className="teamname">{documents.value["Team"]}</td> */}
              <td>{documents.value["BK"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + 'd1-sunday-league' + "/stats/Block-BK"}>See More</a>
        </div>
        <div className="record-container">
      <h3>Rebound Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            {/* <th>Team</th> */}
            <th>RB</th>
          </tr>
        </thead>
        <tbody>
          {reboundLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td className="teamname">{documents.id}</td>
              {/* <td className="teamname">{documents.value["Team"]}</td> */}
              <td>{documents.value["RB"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + 'd1-sunday-league' + "/stats/Rebound-RB"}>See More</a>
</div>
    
    </div>  
    <div className="page records">
        <h2>All Time Records</h2>
        {TPT.length > 0 &&
          (
            <div className="record-container">
              <h3>Single Game {TPT[0].value.id}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Player</th>
            {/* <th>Team</th> */}
                    <th>{TPT[0].value.id}</th>
                  </tr>
                </thead>
                <tbody>
                  {TPT[0].value["Players"].map((player, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td className="teamname">{player.Name}</td>
                      {/* <td className="teamname">{player.Team}</td> */}
                      <td>{player.Score}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {AS.length > 0 &&
          (
            <div className="record-container">
              <h3>Single Game {AS[0].value.id}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Player</th>
            {/* <th>Team</th> */}
                    <th>{AS[0].value.id}</th>
                  </tr>
                </thead>
                <tbody>
                  {AS[0].value["Players"].map((player, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td className="teamname">{player.Name}</td>
                      {/* <td className="teamname">{player.Team}</td> */}
                      <td>{player.Score}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {BK.length > 0 &&
          (
            <div className="record-container">
              <h3>Single Game {BK[0].value.id}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Player</th>
            {/* <th>Team</th> */}
                    <th>{BK[0].value.id}</th>
                  </tr>
                </thead>
                <tbody>
                  {BK[0].value["Players"].map((player, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td className="teamname">{player.Name}</td>
                      {/* <td className="teamname">{player.Team}</td> */}
                      <td>{player.Score}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {TP.length > 0 &&
          (
            <div className="record-container">
              <h3>Single Game {TP[0].value.id}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Player</th>
            {/* <th>Team</th> */}
                    <th>{TP[0].value.id}</th>
                  </tr>
                </thead>
                <tbody>
                  {TP[0].value["Players"].map((player, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td className="teamname">{player.Name}</td>
                      {/* <td className="teamname">{player.Team}</td> */}
                      <td>{player.Score}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {RB.length > 0 &&
          (
            <div className="record-container">
              <h3>Single Game {RB[0].value.id}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Player</th>
            {/* <th>Team</th> */}
                    <th>{RB[0].value.id}</th>
                  </tr>
                </thead>
                <tbody>
                  {RB[0].value["Players"].map((player, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td className="teamname">{player.Name}</td>
                      {/* <td className="teamname">{player.Team}</td> */}
                      <td>{player.Score}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {ST.length > 0 &&
          (
            <div className="record-container">
              <h3>Single Game {ST[0].value.id}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Player</th>
            {/* <th>Team</th> */}
                    <th>{ST[0].value.id}</th>
                  </tr>
                </thead>
                <tbody>
                  {ST[0].value["Players"].map((player, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td className="teamname">{player.Name}</td>
                      {/* <td className="teamname">{player.Team}</td> */}
                      <td>{player.Score}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
      </div>
    </>
  )
}

export default Statistics;