import React from "react";
import {db} from "../Firebase";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";

export const useGetTeams = (league) => {
  const [documents, setDocuments] = React.useState([]);
console.log('_log useGetTeams: ', league)
  const q = query(
    collection(db, "Teams"),
    where("League", "==", league),
    orderBy("Wins", "desc")
  );
  React.useEffect(() => {
    getDocs(q).then((querySnapshot) => {
      let arr = [];
      querySnapshot.docs.map((doc) =>
        arr.push({ id: doc.id, value: doc.data() })
      );
      setDocuments(arr);
    });
  }, [league]);
  console.log('_log useGetTeams documents: ', documents)
  return [documents];
};
