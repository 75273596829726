import React from "react";
import {db} from "../Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";

export const useGetAllTimeStats = (league) => {
  const [documents, setDocuments] = React.useState([]);
  const q = query(
    collection(db, "AllTimeStatLeaders"),
    where("League", "==", league)
  );
  React.useEffect(() => {
    getDocs(q).then((querySnapshot) => {
      let arr = [];
      querySnapshot.docs.map((doc) =>
        arr.push({ id: doc.id, value: doc.data() })
      );
      setDocuments(arr);
    });
  }, []);
  return [documents];
};
