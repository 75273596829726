import React from "react";
import {db} from '../Firebase';

export const useGetCollectionData = (collection) => {
  const [documents, setDocuments] = React.useState([]);
  
  React.useEffect(() => {
    db.collection(collection)
      .get()
      .then((querySnapshot) => {
        let arr = [];
        querySnapshot.docs.map((doc) =>
          arr.push({ id: doc.id, value: doc.data() })
        );
        setDocuments(arr);
      });
  }, []);
  return [documents];
};