import React from "react";
import {db} from "../Firebase";
import { doc, getDoc, collection, query, where, getDocs, orderBy } from "firebase/firestore";

export const useGetPlayoffBracket = (data) => {
  const [winner, setWinner] = React.useState([]);
  const [bracket, setBracket] = React.useState([]);

  React.useEffect(() => {
    db.collection('PlayoffBracket').doc(data.league).get()
      .then(snapshot => setWinner(snapshot.data()))
    }, []);
  React.useEffect(() => {
    db.collection("PlayoffBracket")
    .doc(data.league)
    .collection(data.season)
    .get()
    .then((snapshot) => {
      let arr = [];
      snapshot.docs.map((doc) => arr.push({ id: doc.id, value: doc.data() }));
      setBracket(arr);
    });
  }, [])
  return [winner, bracket];
};
