import React from "react";
import {db} from "../Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  documentId,
  FieldPath,
  getDoc,
} from "firebase/firestore";

export const useGetPOTW = (division, week) => {
  const [documents, setDocuments] = React.useState([]);
  React.useEffect(() => {
    db.collection("POTW")
      .doc(division)
      .collection("Week")
      .get(week)
      .then((snapshot) => {
        let arr = [];
        snapshot.docs.map((doc) => {
          arr.push({ id: doc.id, value: doc.data() })
        });
        setDocuments(arr);
      });
  }, [week]);
  return [documents];
};
