import React from "react";
import {db} from "../Firebase";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";

export const useGetGamesByWeek = (week) => {
  console.log('_log useGetGamesByWeek: ', week)
  const [documents, setDocuments] = React.useState([]);
  const q = query(
    collection(db, "Games"),
    where("Week", "==", week),
    orderBy("Time", "asc")
  );
  React.useEffect(() => {
    getDocs(q).then((querySnapshot) => {
      let arr = [];
      querySnapshot.docs.map((doc) =>
        arr.push({ id: doc.id, value: doc.data() })
      );
      setDocuments(arr);
    });
  }, [week]);
  return [documents];
};
