import React from "react";

const Modal = (param) => {
  return (
    <div className="modal-container">
      <div className="modal">
        <div className="modal--header">
        <h2>PLAYER AGREEMENT</h2>
        </div>
        <div className="modal--content">
          {param.children}
        </div>
        <div className="modal--footer">
        <div className="btn-yellow" onClick={param.openModal}>CLOSE</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
