import React, { useState } from "react";
import Header from "../Home/Header";
import "./leagues.css";

const Leagues = () => {
  const [checked, setChecked] = useState(false);

  function onChange() {
    setChecked(!checked);
  }

  return (
    <div className="leagues">
      <div className="page menu-page">
        <h2>Leagues</h2>
        <ul className="main-menu button-menu">
          <li className="menu-item">
            <a href="/d1-sunday-league">D1 SUNDAY LEAGUE</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Leagues;
