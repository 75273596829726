import React from "react";

const OpenRuns = () => {
  return (
    <div className="page open-runs">
      <h2>OpenRuns</h2>
      <div>Coming Soon</div>
    </div>
  );
};

export default OpenRuns;
