import React from "react";
import {db} from "../Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  documentId,
  FieldPath,
  getDoc,
} from "firebase/firestore";

export const useGetGameResults = (gameid) => {
  const [teams, setTeams] = React.useState([]);
  const [players, setPlayers] = React.useState([]);

  React.useEffect(() => {
    db.collection("GameResults")
      .doc(gameid)
      .collection("Teams")
      .get()
      .then((snapshot) => {
        let arr = [];
        snapshot.docs.map((doc) => arr.push({ id: doc.id, value: doc.data() }));
        setTeams(arr);
      });
  }, []);
  React.useEffect(() => {
    db.collection("GameResults")
      .doc(gameid)
      .collection("Players")
      .get()
      .then((snapshot) => {
        let arr = [];
        snapshot.docs.map((doc) => arr.push({ id: doc.id, value: doc.data() }));
        setPlayers(arr);
      });
  }, []);
  return [teams, players];
};
