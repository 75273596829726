import { useEffect, useState, useRef } from "react";
import "./registration.css";
import emailjs from "@emailjs/browser";
import {db} from "../../Firebase";

const D1SundayLeagueFreeAgentRegistration = () => {
  const form = useRef();
  const [freeAgentList, setFreeAgentList] = useState([])
  useEffect(() => {
    db.collection("FreeAgent")
    .doc('d1-sunday-league')
    .collection("Agents")
    .get()
    .then((snapshot) => {
        let arr = [];
        snapshot.docs.map((doc) => arr.push({ id: doc.id, value: doc.data() }));
        setFreeAgentList(arr);
    });
  }, [])
  //const [freeAgentList] = useGetFreeAgentResults('d1-sunday-league', refresh)
  // setDoc(doc(db, "Waitlist", "test"), { test: "test" });
  //NOTE: When using EmailJS, combine all player information
  //into one big string so it can be sent correctly
  const handleSubmit = (e) => {
    e.preventDefault();
    
    emailjs
      .sendForm(
        "service_8f2pqna",
        "template_4w5sspf",
        form.current,
        "t-FZ46K78lk_GTwLu"
      )
      .then(
        (result) => {
          let data = {
            "First Name": form.current[2].value,
            "Last Name": form.current[3].value,
            "Position": form.current[4].value,
            "Height": form.current[5].value,
            "Instagram": form.current[6].value,
            "Captain Phone": form.current[7].value,
            "Captain Email": form.current[8].value,
            "Division": "d1-sunday-league"
          };
          db.collection('FreeAgent')
          .doc('d1-sunday-league')
          .collection('Agents')
          .doc(data['First Name'] + ' ' + data['Last Name'])
          .set(data).then(() => {
            let newList = [...freeAgentList]
            let newData = {
              id: data['First Name'] + ' ' + data['Last Name'],
              value: data
            }
            newList.push(newData)
            setFreeAgentList(newList)
            e.target.reset();
          })
        },
        (error) => {
          console.log(error.text);
        }
      );

  };

  return (
    <div className="page registration">
      <h2>D1 Sunday League Free Agent Registration<hr/></h2>
      <h3>Registration Details</h3>
      <div className="">
        <p>REGISTER AS A FREE AGENT FOR A
        CHANCE TO BE PICKED UP BY A TEAM IN
        THE MECCA. UPON GETTING SELECTED,
        THE LEAGUE COMMISSIONER WILL
        CONTACT YOU FOR PAYMENT AND LET
        YOU KNOW IF ADDITIONAL FEES ARE
        REQUIRED BY A TEAM CAPTAIN</p>
      </div>
      <form ref={form} onSubmit={handleSubmit}>
        <input hidden name="division" value="Division 1" />
        <input hidden name="otherRegistration" value="Free Agent" />
        <ul className="form-style-1">
          <li className="form-element">
            <label htmlFor="firstName">First Name</label>
            <input id="firstName" name="firstName" type="text" required></input>
          </li>
          <li className="form-element">
            <label htmlFor="lastName">Last Name</label>
            <input id="lastName" name="lastName" type="text" required></input>
          </li>
          <li className="form-element">
            <label htmlFor="position">Position</label>
            <input id="position" name="position" type="text" required></input>
          </li>
          <li className="form-element">
            <label htmlFor="height">Height</label>
            <input id="height" name="height" type="text" required></input>
          </li>
          <li className="form-element">
            <label htmlFor="instagram">Instagram</label>
            <input id="instagram" name="instagram" type="text"></input>
          </li>
          <li className="form-element">
            <label htmlFor="captainPhone">Phone Number</label>
            <input
              id="captainPhone"
              name="captainPhone"
              type="text"
              required
            ></input>
          </li>
          <li className="form-element">
            <label htmlFor="captainEmail">Email</label>
            <input
              id="captainEmail"
              name="captainEmail"
              type="text"
              required
            ></input>
          </li>
          <li>
            <input type="submit" value="Submit" />
          </li>
        </ul>
      </form>
      <br></br>
      <h2>Free Agent List</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>Height</th>
            <th>Instagram</th>
          </tr>
        </thead>
        <tbody>
        {freeAgentList && freeAgentList.length > 0 && freeAgentList.map((item) => (
            <tr key={item.id}>
              <td>{item.value['First Name']} {item.value['Last Name']}</td>
              <td>{item.value['Position']}</td>
              <td>{item.value['Height']}</td>
              <td>{item.value['Instagram']}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default D1SundayLeagueFreeAgentRegistration;
