import React from "react";
import { useGetAllTimeStats } from "../../hooks/useGetAllTimeStats";
import { useGetStats } from "../../hooks/useGetStats";
import "./stats.css";
import { useParams } from "react-router-dom";

const AllTimeStatLeaders = () => {
  const params = useParams()
  const [stats] = useGetAllTimeStats(params.league);

  const pointLeaders = stats.sort(
    (a, b) => parseInt(b.value["TP"]) - parseInt(a.value["TP"])
  );
  let newPointLeaders = pointLeaders.slice(0, 5);
  let threePointLeaders = stats.sort(
    (a, b) => parseInt(b.value["TPT"]) - parseInt(a.value["TPT"])
  );
  threePointLeaders = threePointLeaders.slice(0, 5);
  let assistLeaders = stats.sort(
    (a, b) => parseInt(b.value["AS"]) - parseInt(a.value["AS"])
  );
  assistLeaders = assistLeaders.slice(0, 5);
  let stealLeaders = stats.sort(
    (a, b) => parseInt(b.value["ST"]) - parseInt(a.value["ST"])
  );
  stealLeaders = stealLeaders.slice(0, 5);
  let blockLeaders = stats.sort(
    (a, b) => parseInt(b.value["BK"]) - parseInt(a.value["BK"])
  );
  blockLeaders = blockLeaders.slice(0, 5);
  let reboundLeaders = stats.sort(
    (a, b) => parseInt(b.value["RB"]) - parseInt(a.value["RB"])
  );
  reboundLeaders = reboundLeaders.slice(0, 5);
  return (
    <div className="page stats">
      <h2>All Time Stat Leaders</h2>
      <h3>Point Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>TP</th>
          </tr>
        </thead>
        <tbody>
          {newPointLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              <td>{documents.value["TP"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + params.league + '/statsleaders/Points-TP'}>See More</a>
      <h3>3 Point Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>3PT</th>
          </tr>
        </thead>
        <tbody>
          {threePointLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              <td>{documents.value["TPT"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + params.league + '/statsleaders/3 Point-TPT'}>See More</a>
      <h3>Assists Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>AS</th>
          </tr>
        </thead>
        <tbody>
          {assistLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              <td>{documents.value["AS"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + params.league + '/statsleaders/Assists-AS'}>See More</a>
      <h3>Steal Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>ST</th>
          </tr>
        </thead>
        <tbody>
          {stealLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              <td>{documents.value["ST"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + params.league + '/statsleaders/Steal-ST'}>See More</a>
      <h3>Block Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>BK</th>
          </tr>
        </thead>
        <tbody>
          {blockLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              <td>{documents.value["BK"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + params.league + '/statsleaders/Block-BK'}>See More</a>
      <h3>Rebound Leaders</h3>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>RB</th>
          </tr>
        </thead>
        <tbody>
          {reboundLeaders.map((documents, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{documents.id}</td>
              <td>{documents.value["RB"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
        <a href={"/" + params.league + '/statsleaders/Rebound-RB'}>See More</a>
    </div>
  );
};

export default AllTimeStatLeaders;
