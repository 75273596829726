// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { initializeApp } from 'firebase/app';
import { 
  getAuth,
  onAuthStateChanged, 
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  connectAuthEmulator,
  updateProfile
} from 'firebase/auth';
import { doc, Firestore, setDoc } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyACxl-2YyGhfwBsi7H29YuOGkYHajF5fA4",
  authDomain: "express-basketball-oc.firebaseapp.com",
  projectId: "express-basketball-oc",
  storageBucket: "express-basketball-oc.appspot.com",
  messagingSenderId: "844362600200",
  appId: "1:844362600200:web:e4f8aae78346c39e4166bd"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const loginEmailPassword = async (email, password) => {

  // step 1: try doing this w/o error handling, and then add try/catch
  //await signInWithEmailAndPassword(auth, email, password)

  //step 2: add error handling
  try {
    await signInWithEmailAndPassword(auth, email, password).then(() => {
      window.location = '/account'
    })
    console.log('_log sign in: ', email, password)
  }
  catch(error) {
    alert(error)
    console.log(`There was an error: ${error}`)
  }
}

// Create new account using email/password
const createAccount = async (email, password, firstName, lastName, phoneNumber, team, isCaptain, league, swaps) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      console.log('_log user: ', userCredential, user)
      let data = {
        FirstName: firstName.toUpperCase(),
        LastName: lastName.toUpperCase(),
        Email: email,
        Password: password,
        PhoneNumber: phoneNumber,
        League: league,
        Team: team.toUpperCase(),
        isCaptain: isCaptain,
        Swaps: swaps
      }
      let document = `${firstName.toUpperCase()} ${lastName.toUpperCase()}`
      setDoc(doc(db, 'Players', document), data);

      updateProfile(auth.currentUser, {
        displayName: `${firstName.toUpperCase()} ${lastName.toUpperCase()}`,
        phoneNumber: phoneNumber
      }).then(() => {
        localStorage.setItem("User", JSON.stringify(auth.currentUser));
        window.location = '/account'
      })
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert("Firebase.js: ", errorMessage)
      console.log('_log error: ', errorCode, errorMessage)
      // ..
    });
  }
  catch(error) {
    console.log(`There was an error: ${error}`)
  } 
}

// Monitor auth state
const monitorAuthState = async () => {
  console.log('_log monitorauthstate')
  onAuthStateChanged(auth, user => {
    console.log('_log onauthstatechanged: ', auth, user)
    if (user) {
      console.log('Auth State: ', user)
      localStorage.setItem("User", JSON.stringify(user));
    }
    else {
      console.log('_log not logged in')
    }
  })
}

// Log out
const logout = async () => {
  await signOut(auth).then(() => {
    localStorage.removeItem('User');
  }).then(() => {
    window.location = '/'
  });
}

const auth = getAuth(app);
monitorAuthState();

export {db, logout, createAccount, loginEmailPassword};