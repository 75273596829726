import React from "react";

const TempPage = () => {
  return (
    <div className="page">
      <h2>Under Construction</h2>
      <div>Coming Soon</div>
    </div>
  );
};

export default TempPage;
