/* Calculate weeks between dates
** Difference is calculated by getting date for start of week,
** getting difference, dividing and rounding, then adding 1.
** @param {Date} d0 - date for start
** @param {Date} d1 - date for end
** @param {number} [startDay] - default is 1 (Monday)
** @returns {number} weeks between dates, always positive
*/
export function weeksBetweenDates(d0, d1, startDay) {
  // Default start day to 1 (Monday)
  if (typeof startDay != 'number') startDay = 1;
  // Copy dates so don't affect originals
  d0 = new Date(d0);
  d1 = new Date(d1);

  // Set dates to the start of the week based on startDay
  [d0, d1].forEach(d => d.setDate(d.getDate() + ((startDay - d.getDay() - 7) % 7)));

  // If d1 is before d0, swap them
  if (d1 < d0) {
    var t = d1;
    d1 = d0;
    d0 = t;
  }
  return Math.round((d1 - d0)/6.048e8) + 1;
}
