import React, {useState} from "react";
import {loginEmailPassword} from '../Firebase';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const Signin = () => {
  let user = localStorage.getItem('User');
  user = JSON.parse(user)
  const [signinForm, setSigninForm] = useState({
    email: '',
    password: ''
  });
  const handleChangeSignIn = (event) => {
    setSigninForm({ ...signinForm, [event.target.name]: event.target.value });
  };

  const handleSubmitSignIn = (event) => {
    event.preventDefault();
    loginEmailPassword(signinForm.email, signinForm.password)
    setSigninForm({ email: '',password: '' });
  };

  return (
    <div className="page signup">
      <h2>Sign In</h2>
      <form className="submit-form" onSubmit={handleSubmitSignIn}>
        <div><label>Email<input type="text" name="email" value={signinForm.email} onChange={handleChangeSignIn}></input></label></div>
        <div><label>Password<input type="text" name="password" value={signinForm.password} onChange={handleChangeSignIn}></input></label></div>
        <input type="submit" className="btn-yellow" value="Login"></input>
      </form>
    </div>
  );
};

export default Signin