import React from "react";
import { useGetPlayoffBracket } from "../../hooks/useGetPlayoffBracket";
import { useParams } from "react-router-dom";
import "./playoffbracket.css";
import logo from "../../images/The Mecca_d00a_00a-01.svg";

const PlayoffBracket = () => {
  const data = useParams();
  const [winner, playoffbracket] = useGetPlayoffBracket(data);

  return (
    <div className="playoffBracket page">
      <img src={logo} className="slider--logo"></img>
      <h2>Playoff Bracket</h2>
      {playoffbracket && playoffbracket.length > 0 && (
        <div id="bracketContainer">
          <main id="tournament">
            {playoffbracket[0] &&
              playoffbracket[1] &&
              playoffbracket[2] &&
              playoffbracket[3] && (
                <ul className="round round-1">
                  <li className="spacer">&nbsp;</li>
                  <li
                    className={
                      "game game-top " + playoffbracket[0].value["Team1Result"]
                    }
                  >
                    {playoffbracket[0].value["Team1"]}{" "}
                    <span>{playoffbracket[0].value["Team1Score"]}</span>
                  </li>
                  <li className="game game-spacer">{playoffbracket[0].value["Time"]}</li>

                  <li
                    className={
                      "game game-bottom " +
                      playoffbracket[0].value["Team2Result"]
                    }
                  >
                    {playoffbracket[0].value["Team2"]}{" "}
                    <span>{playoffbracket[0].value["Team2Score"]}</span>
                  </li>
                  <li className="spacer">&nbsp;</li>

                  <li
                    className={
                      "game game-top " + playoffbracket[1].value["Team1Result"]
                    }
                  >
                    {playoffbracket[1].value["Team1"]}{" "}
                    <span>{playoffbracket[1].value["Team1Score"]}</span>
                  </li>
                  <li className="game game-spacer">{playoffbracket[1].value["Time"]}</li>

                  <li
                    className={
                      "game game-bottom " +
                      playoffbracket[1].value["Team2Result"]
                    }
                  >
                    {playoffbracket[1].value["Team2"]}{" "}
                    <span>{playoffbracket[1].value["Team2Score"]}</span>
                  </li>

                  <li className="spacer">&nbsp;</li>

                  <li
                    className={
                      "game game-top " + playoffbracket[2].value["Team1Result"]
                    }
                  >
                    {playoffbracket[2].value["Team1"]}{" "}
                    <span>{playoffbracket[2].value["Team1Score"]}</span>
                  </li>
                  <li className="game game-spacer">{playoffbracket[2].value["Time"]}</li>
                  <li
                    className={
                      "game game-bottom " +
                      playoffbracket[2].value["Team2Result"]
                    }
                  >
                    {playoffbracket[2].value["Team2"]}{" "}
                    <span>{playoffbracket[2].value["Team2Score"]}</span>
                  </li>

                  <li className="spacer">&nbsp;</li>

                  <li
                    className={
                      "game game-top " + playoffbracket[3].value["Team1Result"]
                    }
                  >
                    {playoffbracket[3].value["Team1"]}{" "}
                    <span>{playoffbracket[3].value["Team1Score"]}</span>
                  </li>
                  <li className="game game-spacer">{playoffbracket[3].value["Time"]}</li>
                  <li
                    className={
                      "game game-bottom " +
                      playoffbracket[3].value["Team2Result"]
                    }
                  >
                    {playoffbracket[3].value["Team2"]}{" "}
                    <span>{playoffbracket[3].value["Team2Score"]}</span>
                  </li>

                  <li className="spacer">&nbsp;</li>
                </ul>
              )}
            {playoffbracket[4] && playoffbracket[5] && (
              <ul className="round round-2">
                <li className="spacer">&nbsp;</li>

                <li
                  className={
                    "game game-top " + playoffbracket[4].value["Team1Result"]
                  }
                >
                  {playoffbracket[4].value["Team1"]}{" "}
                  <span>{playoffbracket[4].value["Team1Score"]}</span>
                </li>
                <li className="game game-spacer">{playoffbracket[4].value["Time"]}</li>
                <li
                  className={
                    "game game-bottom " + playoffbracket[4].value["Team2Result"]
                  }
                >
                  {playoffbracket[4].value["Team2"]}{" "}
                  <span>{playoffbracket[4].value["Team2Score"]}</span>
                </li>

                <li className="spacer">&nbsp;</li>

                <li
                  className={
                    "game game-top " + playoffbracket[5].value["Team1Result"]
                  }
                >
                  {playoffbracket[5].value["Team1"]}{" "}
                  <span>{playoffbracket[5].value["Team1Score"]}</span>
                </li>
                <li className="game game-spacer">{playoffbracket[5].value["Time"]}</li>
                <li
                  className={
                    "game game-bottom " + playoffbracket[5].value["Team2Result"]
                  }
                >
                  {playoffbracket[5].value["Team2"]}{" "}
                  <span>{playoffbracket[5].value["Team2Score"]}</span>
                </li>

                <li className="spacer">&nbsp;</li>
              </ul>
            )}
            {playoffbracket[6] && (
              <ul className="round round-3">
                <li className="spacer">&nbsp;</li>
                <li
                  className={
                    "game game-top " + playoffbracket[6].value["Team1Result"]
                  }
                >
                  {playoffbracket[6].value["Team1"]}{" "}
                  <span>{playoffbracket[6].value["Team1Score"]}</span>
                </li>
                <li className="game game-spacer">{playoffbracket[6].value["Time"]}</li>
                {playoffbracket[6] && (
                  <li
                    className={
                      "game game-bottom " +
                      playoffbracket[6].value["Team2Result"]
                    }
                  >
                    {playoffbracket[6].value["Team2"]}{" "}
                    <span>{playoffbracket[6].value["Team2Score"]}</span>
                  </li>
                )}

                <li className="spacer">&nbsp;</li>
              </ul>
            )}
            <ul className="round round-4">
              <li className="spacer">&nbsp;</li>
              {winner.Winner && (
                <li className="game game-top winner">{winner.Winner}</li>
              )}
              <li className="spacer">&nbsp;</li>
            </ul>
          </main>
        </div>
      )}
    </div>
  );
};

export default PlayoffBracket;
