import React from "react";
import {db} from "../Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  documentId,
  FieldPath,
  getDoc,
} from "firebase/firestore";

export const useGetTeam = (team) => {
  const [players, setPlayers] = React.useState([]);

  React.useEffect(() => {
    db.collection("Teams")
      .doc(team)
      .collection("Players")
      .get()
      .then((snapshot) => {
        let arr = [];
        snapshot.docs.map((doc) => arr.push({ id: doc.id, value: doc.data() }));
        setPlayers(arr);
      });
  }, []);
  return players
};
