import React from "react";
import "./results.css";
import "../../App.css";

const EventBox = (data) => {
  console.log("_log data: ", data);
  return (
    <div className="eventbox" key={data.game.id}>
      <a key={data.game.id} href={`/game-results/${data.league}/${data.game.id}`}>
        <div className="eventbox--details results">
          <div className="eventbox--details-teams">
            <div className="details-teams-column">
              <div className="homeaway">HOME</div>
              <div>{data.game.value["HOME"]} </div>
            </div>
            {/* <span>
                {data.gameResults.value["Team1Score"]}/
                {data.gameResults.value["Team2Score"]}
              </span> */}
            <div className="details-teams-column">
              <div className="eventbox--details-datetime">
                <div>@ {data.game.value["Time"]}</div>
              </div>
              <div className="spacer">vs</div>
            </div>
            <div className="details-teams-column">
              <div className="homeaway">AWAY</div>
              <div>{data.game.value["AWAY"]}</div>
            </div>
          </div>
        </div>
        {/* <a className="btn" href={`/game-results/${data.game.id}`}>
            Game Result
          </a> */}
        <div className="btn-yellow">Game Result</div>
      </a>
      {/* {!data.hasResults && (
        <>
          <div className="eventbox--details schedule">
            <div className="eventbox--details-teams">
              <div className="eventbox--details-team">
                <span>HOME</span>
                {data.game.value["HOME"]}
              </div>
              <span className="spacer">vs</span>
              <div className="eventbox--details-team">
                <span>AWAY</span>
                {data.game.value["AWAY"]}
              </div>
            </div>
            <div className="eventbox--details-datetime">
              <div>@ {data.game.value["Time"]}</div>
            </div>
          </div>
        </>
      )} */}
    </div>
  );
};

export default EventBox;
