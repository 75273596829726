 import React from "react";
import './gallery.css';
import '../../App.css';


const Gallery = () => {
    return (
        <div className="page gallery menu-page">
            <h2>Gallery</h2>
            <ul className="main-menu button-menu">
                <li className="menu-item">
                    <a href="/gallery/d1-sunday-league">D1 SUNDAY LEAGUE</a>
                </li>
                {/* <li className="menu-item">
                    <a href="/gallery/d2-sunday-league">D2 SUNDAY LEAGUE</a>
                </li> */}
            </ul>
        </div>
    )
}

export default Gallery;